
class AppController implements angular.IComponentController {
    form: any;

    constructor(
        private $rootScope: any,
        private $scope: any,
        private $stateParams: angular.ui.IStateParamsService,
        private UTMService: any
    ) {
        'ngInject';
    }

    $onInit() {
        this.captureUTM();
        this.$rootScope.formSettings = this.form;
    }

    private captureUTM(): void {
        const utmParams: any = {};

        ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((key) => {
            if (this.$stateParams[key]) {
                utmParams[key] = this.$stateParams[key];
            }
        });
        this.UTMService.saveUTM(utmParams);
    }

}

export class AppComponent implements angular.IComponentOptions {
    static selector = 'app';
    static bindings = {
        form: '='
    };
    static controller = AppController;
    static template = require('./app.component.html');
}
