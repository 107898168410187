export class UTMService {
    static selector = 'UTMService';
    utmParams: any = {};

    constructor() {
        'ngInject';

        return this;
    }

    saveUTM(params: any): void {
        this.utmParams = {...this.utmParams, ...params};
    }

    getUTM(): any {
        return this.utmParams;
    }

}
