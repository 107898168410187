export class BootstrapController implements angular.IComponentController {
    static selector = 'BootstrapController';
    title = 'MC Forms';

    constructor(
        private $rootScope: any,
        private $scope: any
    ) {
        'ngInject';
    }

    $onInit() {
        this.$rootScope.$watch('formSettings', (formSettings: any) => {
            if (formSettings) {
                if ((formSettings.partner || {}).companyName) {
                    this.title = `${formSettings.title} | ${formSettings.partner.companyName}`;
                }
            }
        }, true);
    }
}
