import * as angular from 'angular';
import 'jquery-mask-plugin';
import 'jquery-slimscroll';
import 'angular-local-storage';
import 'angular-bootstrap-colorpicker';
import 'angular-chosen-localytics';
import 'angular-i18n/angular-locale_pt-br.js';
import 'restangular';
import 'ng-file-upload';
import 'angular-material';
import 'angular-messages';
import 'angular-aria';
import 'angular-scroll';
import 'angular-highlightjs';
import 'ui-select';
import 'angular-sanitize';
import 'angular-gridster';
import 'ng-mask';
import 'angular-input-masks';
import 'string-mask';
import 'ngmap';
import 'v-accordion';
import 'angular-ui-tree';
import 'angular-timer';
import 'ui-select/dist/select.min.css';
import 'angular-material/angular-material.min.css';
import 'angular-gridster/dist/angular-gridster.min.css';
import 'angular-bootstrap-colorpicker/css/colorpicker.min.css';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';
import 'fontawesome-4.7/fonts/FontAwesome.otf';
import 'fontawesome-4.7/fonts/fontawesome-webfont.eot';
import 'fontawesome-4.7/fonts/fontawesome-webfont.svg';
import 'fontawesome-4.7/fonts/fontawesome-webfont.ttf';
import 'fontawesome-4.7/fonts/fontawesome-webfont.woff';
import 'fontawesome-4.7/fonts/fontawesome-webfont.woff2';
import 'fontawesome-4.7/css/font-awesome.min.css';

// modules
import {moduleName as coreModule} from './core/core.module';
import {moduleName as networkingModule} from './networking/networking.module';
import {moduleName as utilsModule} from './utils/utils.module';
import {moduleName as formFieldModule} from './modules/formField/formField.module';
import {moduleName as customFieldModule} from './modules/customfield/customfield.module';
import {moduleName as layoutModule} from './layout/layout.module';
import {moduleName as I18nModule} from './i18n/i18n.module';

export const moduleName =
    angular.module('application', [
        'ngMessages',
        'ngAria',
        'ngFileUpload',
        'LocalStorageModule',
        'restangular',
        'ngMaterial',
        'ui.select',
        'ngSanitize',
        'gridster',
        'ui.utils.masks',
        'localytics.directives',
        'ngMask',
        'duScroll',
        'colorpicker.module',
        'timer',
        'ngMap',
        'ui.tree',
        'vAccordion',
        networkingModule,
        utilsModule,
        I18nModule,
        layoutModule,
        coreModule,
        formFieldModule,
        customFieldModule
    ])
        .name;
