import * as angular from 'angular';
import _ = require('lodash');
import moment = require('moment');

class CustomFieldCreateController implements angular.IComponentController {
    fieldType: any;
    fieldValues: any;
    fieldModel: { k: any, v: any, customField: any };
    val: any;
    fieldId: any;
    fieldLabel: any;
    fieldChanged: any;

    constructor(
        private $scope: any
    ) {
        'ngInject';
    }

    validateDate(str: any) {
        let dateFormats = {
            'iso_int': 'YYYY-MM-DD',
            'short_date': 'DD/MM/YYYY',
            'iso_date_time': 'YYYY-MM-DDTHH:MM:SS',
            'iso_date_time_utc': 'YYYY-MM-DDTHH:MM:SSZ',
            'iso_date_time_8601': 'YYYY-MM-DDTHH:mm:ss.sssZ'
        };

        function getFormat(d: any): any {
            for (let prop in dateFormats) {
                // @ts-ignore
                if (moment(d, dateFormats[prop], true).isValid()) {
                    // @ts-ignore
                    return dateFormats[prop];
                }
            }
            return null;
        }

        try {
            let formatFound = getFormat(str);
            if (!formatFound) {
                return undefined;
            }

            let date = moment(str, formatFound).utc();
            if (date.isValid()) {
                return String(moment(date).format('YYYY-MM-DD'));
            }

        } catch (e) {
            return '';
        }
    }

    $onInit() {
        switch (this.fieldType) {
            case 'enum':
                let result = _.find(this.fieldValues, (o) => o._id === ((this.fieldModel || {}).v ? this.fieldModel.v._id : null));
                this.val = result;
                break;
            case 'set':
                this.val = _.map((this.fieldModel || {}).v, (field) => {
                    let fieldFound = _.find(this.fieldValues, (o) => o._id === (field ? field._id : null));
                    return fieldFound ? fieldFound : field;
                });
                break;
            case 'date':
                this.val = this.validateDate((this.fieldModel || {}).v);
                break;
            default:
                this.val = (this.fieldModel || {}).v;
                break;
        }
    }

    valChange = (data: any) => {
        this.fieldModel = {
            customField: this.fieldId,
            k: this.fieldLabel,
            v: data
        };
        this.fieldChanged({data: this.fieldModel});
    }
}

export class CustomFieldCreateComponent implements angular.IComponentOptions {
    static selector = 'customFieldCreate';
    static bindings = {
        fieldChanged: '&?',
        fieldId: '<?',
        fieldModel: '=?',
        fieldType: '<?',
        fieldLabel: '<?',
        fieldDisabled: '=?',
        fieldValues: '<?',
        fieldRequired: '<?'
    };
    static controller = CustomFieldCreateController;
    static template = require('./create.html');
    static options = {
        scope: true
    };
}
