import angular = require('angular');
import moment = require('moment');

class FormFieldController implements angular.IComponentController {
    settings: any;
    formData: any = {
        type: 'physical',
        phones: [{typePhone: 'cellphone', number: ''}],
        customFields: []
    };
    formComponent?: any;
    loading?: boolean;

    constructor(
        private $urlRouter: any,
        private $scope: angular.IScope,
        private $rootScope: any,
        private generalUtils: any,
        private FormsService: any
    ) {
    }

    save(form: any = this.formComponent, $event: any) {
        form.$setSubmitted();

        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        if (form.$invalid) {
            return this.generalUtils.onError('Ops!', 'Verifique se todos os campos foram preenchidos corretamente.', 'ok', () => {
                if ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }
            });
        }

        if (((this.formData || {}).favoriteChannelToCommunication || []).length >= 2) {
            let channelSet = new Set();
            this.formData.favoriteChannelToCommunication.forEach((e: any) => channelSet.add(e));
            this.formData.favoriteChannelToCommunication = Array.from(channelSet);
        }

        if (this.formData.type === 'physical') {
            if (this.formData.fullname) {
                [this.formData.name = '', this.formData.lastname = ''] = this.formData.fullname.split(/ (.+)/);
            }
            this.formData.doc = this.generalUtils.formatCPForCNPJ(this.formData.doc);
        } else {
            this.formData.doc = this.generalUtils.formatCPForCNPJ(this.formData.doc);
        }

        if (this.formData.birthDate) {
            this.formData.birthDate = moment(this.formData.birthDate, 'DD/MM/YYYY').toISOString();
        }

        this.loading = true;
        this.formData.customFields.filter((obj: any) => obj);
        this.formData.source = 'form';
        this.FormsService.saveForm(this.formData, this.settings)
            .then((data: any) => {
                delete this.loading;
                this.generalUtils.onSuccess('Cliente salvo!', 'Novo cliente cadastrado com sucesso.', 'ok', '', () => {
                    this.clear();
                });
            })
            .catch((err: any) => {
                delete this.loading;
                this.showCustomerCreateError(err);
            });
    }

    clear() {
        this.formData = {
            customFields: []
        };
    }

    private showCustomerCreateError(err: any) {
        this.generalUtils.hideLoader();
        let codeErr = err.data.message ? err.data.message.code : err.status;
        let message = 'Erro ao salvar o usuário, tente novamente  ';

        if (err.data && err.data.message && err.data.message.code) {
            switch (codeErr) {
                case 11000:
                case 409:
                    message = 'Um usuário com estas informações já foi cadastrado para esta conta, encontre-o na listagem de clientes';
                    break;
                case 400:
                    message = 'É necessário preencher todos os campos obrigatórios';
                    break;
            }
        }

        this.generalUtils.onError('Ops!', message, 'Confirmar', function (isConfirm: any) {
        });
    }
}

export class FormFieldComponent implements angular.IComponentOptions {
    static selector = 'formFieldComponent';
    static bindings = {
        settings: '='
    };
    static controller = FormFieldController;
    static template = require('./form.html');
}
