import * as angular from 'angular';
import _ = require('lodash');
import moment = require('moment');

export class FormFieldViewController implements angular.IComponentController {
    field: any;
    model: any;
    modelForm: any;
    keys: any[];
    personTypes: { person: string; type: string; }[];
    genderTypes: { description: string; gender: string; }[];
    calendar: { dateOptions: { maxDate: Date; }; };
    states: any;
    staffList: any;
    loading: any;
    loadingCities: boolean;
    cities: any;

    constructor(
        private $scope: angular.IScope,
        private $mdConstant: any,
        private Restangular: any,
        private CustomerService: any,
        private $rootScope: any,
        private StaticsService: any,
        private StaffsService: any
    ) {
        'ngInject';
    }

    $onInit() {
        this.keys = [this.$mdConstant.KEY_CODE.ENTER, this.$mdConstant.KEY_CODE.COMMA, 188];
        this.personTypes = [
            {person: 'Pessoa Física', type: 'physical'},
            {person: 'Pessoa Jurídica', type: 'legal'}
        ];
        this.genderTypes = [
            {description: 'Masculino', gender: 'M'},
            {description: 'Feminino', gender: 'F'}
        ];
        this.calendar = {
            dateOptions: {
                maxDate: new Date(),
            }
        };

        if (!this.field.system && !this.model.customFields[this.field._id]) {
            this.model.customFields[this.field._id] = {customField: this.field._id, k: this.field.name, v: null};
        }

        switch (this.field.fieldRef) {
            case 'contactObj.state':
                this.StaticsService.getBrazilianStates().then((states: any) => {
                    this.states = states;
                    delete this.loading;
                    try {
                        this.$scope.$apply();
                    } catch (e) {
                    }
                });
                break;
            case 'contactObj.city':
                this.$scope.$watch(() => {
                    return this.model.state;
                }, (state) => {
                    if (state) {
                        this.loadingCities = true;

                        this.StaticsService.getCitiesOfBrazilianStateByName(state)
                            .then((cities: any) => {
                                delete this.loadingCities;
                                this.cities = cities;

                                try {
                                    this.$scope.$apply();
                                } catch (e) {
                                }
                            });
                    }
                });
                break;
            case 'contactObj.birthDate':
                this.$scope.$watch(() => {
                    return this.model.birthDate;
                }, (birthDate) => {
                    if (birthDate) {
                        (this.model || {}).age = moment().diff(moment(this.model.birthDate, 'DD/MM/YYYY'), 'years');
                    }
                }, true);
                break;
            case 'contactObj.owners':
                this.StaffsService.getCurrentBranchStaffs('displayedContactStaffList')
                    .then((staffs: any) => {
                        this.staffList = staffs;

                        try {
                            this.$scope.$apply();
                        } catch (e) {
                        }
                    });
                break;
        }
    }

    fieldChanged(data: any) {
        if (!_.isEmpty(this.field.dependentFields)) {
            for (let dfield of this.field.dependentFields) {
                this.$rootScope.$broadcast(`${this.field.feature}_${dfield}`, {...data, subField: dfield});
            }
        }
    }

    newPhone() {
        this.model.phones = [...(this.model.phones || []), {typePhone: 'cellphone', number: ''}];
    }

    removePhone = (index: any) => this.model.phones.splice(index, 1);
}

export class FormFieldViewComponent implements angular.IComponentOptions {
    static selector = 'formFieldView';
    static bindings = {
        field: '=',
        model: '=',
        modelForm: '='
    };
    static controller = FormFieldViewController;
    static template = require('./view.html');
}
