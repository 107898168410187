import angular = require('angular');
import moment = require('moment');

class FormFieldController implements angular.IComponentController {
    settings: any;
    styles: any;
    formData: any = {
        type: 'physical',
        phones: [{typePhone: 'cellphone', number: ''}],
        customFields: {}
    };
    formComponent?: any;
    loading?: boolean;
    settingsColor: string = '#8955ef';
    styleInput: string = 'default';

    constructor(
        private $urlRouter: any,
        private $scope: angular.IScope,
        private $rootScope: any,
        private generalUtils: any,
        private FormsService: any,
        private $window: angular.IWindowService,
        private UTMService: any
    ) {
        $scope.$watch('$ctrl.settings', () => {
            if (this.settings) {
                if (this.settings.color) {
                    this.settingsColor = this.settings.color;
                }
            }
        });
        $scope.$watch('$ctrl.styles', () => {
            if (this.styles) {
                if (this.styles.fieldType) {
                    this.styleInput = this.styles.fieldType;
                }
            }
        });
    }

    $onInit() {
        this.$rootScope.$watch('formSettings', (formSettings: any) => {
            if (formSettings) {
                if (formSettings.settings?.loadScriptOnPageOpen) {
                    try {
                        let loadScriptOnPageOpen = formSettings.settings.loadScriptOnPageOpen;
                        const result = loadScriptOnPageOpen.replace(/<\s*script(?:[^>]*)>(.*?)<\s*\/\s*script\s*>/gis, '$1');
                        const fragment = document.createRange().createContextualFragment(`<script type='text/javascript'>${this.generalUtils.formatScript(result, this.formData)}</script>`);
                        document.body.appendChild(fragment);
                    } catch (e) {
                        console.log(`loadScriptOnPageOpen Err::`, e);
                    }

                }
                if (formSettings.settings?.loadScriptOnPageClose) {
                    try {
                        this.$window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
                            event.preventDefault();

                            let loadScriptOnPageClose = formSettings.settings.loadScriptOnPageClose;
                            const result = loadScriptOnPageClose.replace(/<\s*script(?:[^>]*)>(.*?)<\s*\/\s*script\s*>/gis, '$1');
                            let load = new Function(this.generalUtils.formatScript(result, this.formData));
                            return load();
                        });
                    } catch (e) {
                        console.log(`loadScriptOnPageClose Err::`, e);
                    }

                }
            }
        }, true);
    }

    save(form: any = this.formComponent, $event: any) {
        form.$setSubmitted();

        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        if (form.$invalid) {
            return this.generalUtils.onError('Ops!', 'Verifique se todos os campos foram preenchidos corretamente.', 'ok', () => {
                if ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }
            });
        }

        if (this.formData.type === 'physical') {
            if (this.formData.fullname) {
                [this.formData.name = '', this.formData.lastname = ''] = this.formData.fullname.split(/ (.+)/);
            }
            this.formData.doc = this.generalUtils.formatCPForCNPJ(this.formData.doc);
        } else {
            this.formData.doc = this.generalUtils.formatCPForCNPJ(this.formData.doc);
        }
        if (this.formData.birthDate) {
            this.formData.birthDate = moment(this.formData.birthDate, 'DD/MM/YYYY').toISOString();
        }

        this.loading = true;
        this.formData.customFields = Object.values(this.formData.customFields);
        this.formData.customFields.filter((obj: any) => obj);

        this.formData.source = 'form';

        let utmParams = this.UTMService.getUTM();
        this.formData.utm = {
            utmSource: utmParams.utm_source,
            utmMedium: utmParams.utm_medium,
            utmCampaign: utmParams.utm_campaign,
            utmTerm: utmParams.utm_term,
            utmContent: utmParams.utm_content
        };

        this.FormsService.saveForm(this.formData, this.settings)
            .then((data: any) => {
                delete this.loading;

                if (this.settings.loadScriptOnSubmitButton) {
                    try {
                        let loadScriptOnSubmitButton = this.settings.loadScriptOnSubmitButton;
                        const result = loadScriptOnSubmitButton.replace(/<\s*script(?:[^>]*)>(.*?)<\s*\/\s*script\s*>/gis, '$1');
                        let load = new Function(this.generalUtils.formatScript(result, this.formData));
                        load();
                    } catch (e) {
                        console.log(`loadScriptOnSubmitButton Err::`, e);
                    }

                }

                switch (this.settings.formAfterSent) {
                    case 'redirect_page':
                        window.location.href = this.settings.redirectPage;
                        break;
                    case 'thanks_page':
                        this.generalUtils.onSuccess('', this.settings.thanksPage, 'OK', '', () => {
                            this.clear();
                        });
                        break;
                    default:
                        break;
                }
            })
            .catch((err: any) => {
                delete this.loading;
                this.showCustomerCreateError(err);
            });
    }

    clear() {
        this.formData = {
            type: 'physical',
            phones: [{typePhone: 'cellphone', number: ''}],
            customFields: {}
        };
        this.formComponent.$setPristine();
        this.formComponent.$setUntouched();
        this.$rootScope.$broadcast('FORM_CLEAR');
    }

    private showCustomerCreateError(err: any) {
        this.generalUtils.hideLoader();
        let codeErr = err.data.message ? err.data.message.code : err.status;
        let message = 'Erro ao salvar o usuário, tente novamente  ';

        if (err.data && err.data.message && err.data.message.code) {
            switch (codeErr) {
                case 11000:
                case 409:
                    message = 'Um usuário com estas informações já foi cadastrado para esta conta, encontre-o na listagem de clientes';
                    break;
                case 400:
                    message = 'É necessário preencher todos os campos obrigatórios';
                    break;
            }
        }

        this.generalUtils.onError('Ops!', message, 'Confirmar', function (isConfirm: any) {
        });
    }
}

export class FormFieldComponent implements angular.IComponentOptions {
    static selector = 'formFieldComponent';
    static bindings = {
        settings: '=',
        styles: '='
    };
    static controller = FormFieldController;
    static template = require('./form.html');
}
