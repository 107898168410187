import * as angular from 'angular';
import * as _ from 'lodash-es';

export class FormFieldListController implements angular.IComponentController {
    modelForm: any;
    model: any;
    loading?: boolean;

    featureCustomFields: any[] = [];
    fields: any[] = [];
    subFields: any[] = [];
    customFieldBlocks: any[] = [];
    groupedFeatureCustomFields: any = [];

    constructor(
        private $rootScope: any,
        private $scope: angular.IScope,
        private generalUtils: any,
        private StaticsService: any
    ) {
        'ngInject';
    }

    $onInit() {
        this.reloadForm();

        this.$rootScope.$on('FORM_CLEAR', () => {
            this.reloadForm();
        });
    }

    private reloadForm() {
        let self = this;
        this.loading = true;

        let promises: Promise<any[]>[] = [
            this.StaticsService.getCustomFields({'subFieldSettings.active': true}),
            this.StaticsService.getCustomFields({'subFieldSettings.active': false}),
            this.StaticsService.getCustomFieldBlocks()
        ];
        Promise.all(promises)
            .then(([subFields, fields, customFieldBlocks]) => {
                delete this.loading;
                this.customFieldBlocks = customFieldBlocks;

                this.fields = this.getFieldsFiltered(fields, self.$rootScope.formSettings.components);
                this.featureCustomFields = this.fields;
                this.subFields = subFields;

                this.registerSubFieldListeners(subFields);
                this.processDependentFields(this.fields);
                this.groupAndOrderCustomFields();


                this.safeApply();
            })
            .catch((e) => {
                delete this.loading;
                this.safeApply();
            });
    }

    private getFieldsFiltered(fields: any[], components: any[]): any[] {
        return components.map(component => {
            let field = _.find(fields, (f) => component.customField._id === f._id);
            if (field) {
                field.position = component.position;
                return field;
            }
        }).filter(Boolean);
    }

    private registerSubFieldListeners(subFields: any[]): void {
        for (const sfield of subFields) {
            this.$rootScope.$on(`${sfield.feature}_${sfield._id}`, (observer: any, data: any) => {
                if (data.customField) {
                    let fieldParent = _.find(this.featureCustomFields, (fcf) => String(fcf._id) === String(data.customField));
                    sfield.position = parseFloat(`${fieldParent.position}.${sfield.position}`);
                }
                this.handleSubFieldEvent(sfield, data);
            });
        }
    }

    private handleSubFieldEvent(field: any, data: any): void {
        let existingField = _.find(this.featureCustomFields, (fcf) => String(fcf._id) === String(field._id));
        let isValueMatch = this.checkValueMatch(field, data.v);

        if (isValueMatch && !existingField) {
            this.addFieldToFeatureList(field);
        } else if (!isValueMatch && existingField) {
            this.removeFieldFromFeatureList(existingField);
        }
    }

    private checkValueMatch(field: any, value: any): boolean {
        if (this.isObject(value)) {
            return !!_.find(field.subFieldSettings.values, (o) => String(o._id) === String(value._id));
        } else if (this.isArray(value)) {
            return value.some((val: any) =>
                !!_.find(field.subFieldSettings.values, (o) => String(o._id) === String(val._id))
            );
        }
        return !!value;
    }

    private addFieldToFeatureList(field: any): void {
        this.featureCustomFields.push(field);
        let fieldInModel = this.model.customFields[field._id];
        if (!_.isEmpty(field.dependentFields)) {
            field.dependentFields.forEach((dfield: any) => {
                this.$rootScope.$broadcast(`${field.feature}_${dfield}`, {
                    v: (fieldInModel || {}).v,
                    subField: dfield
                });
            });
        }
        this.groupAndOrderCustomFields();
    }

    private removeFieldFromFeatureList(field: any): void {
        if (!field.system) {
            delete this.model.customFields[field._id];
        }

        _.remove(this.featureCustomFields, (fcf) => String(fcf._id) === String(field._id));

        if (!_.isEmpty(field.dependentFields)) {
            field.dependentFields.forEach((dfield: any) => {
                this.$rootScope.$broadcast(`${field.feature}_${dfield}`, {v: null, subField: dfield});
            });
        }
        this.groupAndOrderCustomFields();
    }

    private processDependentFields(fields: any[]): void {
        fields.forEach((field) => {
            if (!this.model.customFields) {
                this.model.customFields = {};
            }
            let fieldInModel = this.model.customFields[field._id];
            if (!_.isEmpty(field.dependentFields)) {
                field.dependentFields.forEach((dfield: any) => {
                    this.$rootScope.$broadcast(`${field.feature}_${dfield}`, {
                        v: (fieldInModel || {}).v,
                        subField: dfield
                    });
                });
            }
        });
    }

    private safeApply(): void {
        setTimeout(() => {
            this.$scope.$apply();
        }, 100);
    }

    private groupAndOrderCustomFields(): void {
        const grouped = _.groupBy(this.featureCustomFields, (field) => field.customFieldBlockAttach?._id || 'unassigned');

        this.groupedFeatureCustomFields = Object.keys(grouped).map((blockId) => {
            const block = this.customFieldBlocks.find((blk) => blk._id === blockId) || {};
            const orderedFields = grouped[blockId].sort((a, b) => this.getPosition(a.position) - this.getPosition(b.position));
            const hasRequiredField = orderedFields.some((field) => field.settings?.required);

            return {
                _id: block._id || blockId,
                name: block.name || '',
                position: this.getPosition(block.position),
                val: orderedFields,
                isExpanded: block.isExpanded || false,
                required: hasRequiredField
            };
        });

        this.groupedFeatureCustomFields.sort((a: any, b: any) => this.getPosition(a.position) - this.getPosition(b.position));
    }

    private isObject(value: any): boolean {
        return typeof value === 'object' && value !== null && !Array.isArray(value);
    }

    private isArray(value: any): boolean {
        return Array.isArray(value);
    }

    private getPosition(value: any): number {
        return typeof value === 'number' ? value : Number.MAX_VALUE;
    }
}

export class FormFieldListComponent implements angular.IComponentOptions {
    static selector = 'formFieldList';
    static bindings = {
        modelForm: '=',
        model: '=',
        loading: '=?'
    };
    static controller = FormFieldListController;
    static template = require('./list.html');
}
